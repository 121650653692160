import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Modal from "./Modal";
import { GoSearch } from "react-icons/go";
import { FaDice } from "react-icons/fa";
import { BsFillPlayCircleFill } from "react-icons/bs";
import YTPlaceholder from "../images/yt-placeholder.jpg";

const IkshanaLearningYt = "https://www.youtube.com/@ikshanalearning8787/videos";

const getThumbnailUrl = (videoId) => {
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
};

const shuffler = (unshuffled) => {
    return unshuffled
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
}

export default function YoutubeDemo() {
    const data = useStaticQuery(graphql`
    {
      allYoutubeJson {
        edges {
          node {
            id
            title
            videoId
          }
        }
      }
    }
  `);

    const [ytVideoData, setYTVideoData] = useState([])
    const [ytVideoId, setYtVideoId] = useState("");

    useEffect(()=> {
        setYTVideoData(shuffler(data.allYoutubeJson.edges).slice(0, 4))
    }, [])

    const handlePlay = (videoId) => {
        setYtVideoId(videoId);
    };

    const handleClose = () => {
        setYtVideoId("");
    };

    const handleShuffle = () => {
        setYTVideoData(shuffler(data.allYoutubeJson.edges).slice(0, 4));
    }

    return (
        <section className="container testimonial">
            <h2 className="title">Experience our teaching style firsthand</h2>
            <h3 className="subtitle">Explore our video showcase on Youtube.</h3>
            <div className="thumbnail">
                {ytVideoData.map(({ node }) => {
                    const thumbnailUrl = getThumbnailUrl(node.videoId);
                    const placeholderUrl = YTPlaceholder;
                    const imageUrl = new Image();
                    imageUrl.src = thumbnailUrl;

                    return (
                        <div className="thumbnail__wrapper" key={node.id}>
                            <div onClick={() => handlePlay(node.videoId)}>
                                <img
                                    alt={node.title}
                                    src={imageUrl.complete ? thumbnailUrl : placeholderUrl}
                                    loading="lazy"
                                />
                                <div className="thumbnail__overlay">
                                    <BsFillPlayCircleFill className="thumbnail__play" />
                                </div>
                            </div>
                            <h4 style={{ textAlign: "center" }}>{node.title}</h4>
                        </div>
                    );
                })}
            </div>

            <div className="flex-between" style={{ gap: "2rem" }}>
                <button
                    className="button button--medium button--basic button--compliment"
                    style={{ margin: "auto" }}
                    onClick={handleShuffle}
                >
                    <FaDice /> &nbsp; Shuffle
                </button>
                <a
                    href={IkshanaLearningYt}
                    target="_blank"
                    rel="noreferrer"
                    className="button button--medium button--basic"
                    style={{ margin: "auto" }}
                >
                    <GoSearch /> &nbsp; Explore
                </a>
            </div>

            {!!ytVideoId && (
                <Modal open={!!ytVideoId} onClose={handleClose} type="video">
                    <iframe
                        className="video-iframe"
                        src={`https://www.youtube.com/embed/${ytVideoId}?autoplay=1`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </Modal>
            )}
        </section>
    );
}
