import * as React from "react"

const TestIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        fill="currentColor"
        className="BIIPvCRqoO6eeiLt4mCWl"
        {...props}
    >
        <path d="M171.759 128h14.518c5.522 0 10-4.477 10-10s-4.478-10-10-10h-14.518a2.299 2.299 0 0 1-2.296-2.296V96h12.993c5.522 0 10-4.477 10-10s-4.478-10-10-10h-12.993v-9.704A2.299 2.299 0 0 1 171.759 64h14.518c5.522 0 10-4.477 10-10s-4.478-10-10-10h-14.518c-12.294 0-22.296 10.002-22.296 22.296v39.408c0 12.294 10.002 22.296 22.296 22.296zm67.495-20h-17.167c-5.522 0-10 4.477-10 10s4.478 10 10 10h17.167c14.337 0 26-11.664 26-26s-11.663-26-26-26h-1.167c-3.309 0-6-2.691-6-6s2.691-6 6-6h11.512c5.522 0 10-4.477 10-10s-4.478-10-10-10h-11.512c-14.337 0-26 11.664-26 26s11.663 26 26 26h1.167c3.309 0 6 2.691 6 6s-2.691 6-6 6zm-110-64h-33c-5.522 0-10 4.477-10 10s4.478 10 10 10h6.5v54c0 5.523 4.478 10 10 10s10-4.477 10-10V64h6.5c5.522 0 10-4.477 10-10s-4.477-10-10-10zm159.5 20h6.5v54c0 5.523 4.478 10 10 10s10-4.477 10-10V64h6.5c5.522 0 10-4.477 10-10s-4.478-10-10-10h-33c-5.522 0-10 4.477-10 10s4.478 10 10 10zM120.462 168h-40c-5.522 0-10 4.477-10 10v40c0 5.523 4.478 10 10 10h40c5.522 0 10-4.477 10-10v-40c0-5.523-4.477-10-10-10zm-10 40h-20v-20h20v20zm213.164 10c0 2.63 1.069 5.21 2.93 7.07s4.44 2.93 7.07 2.93 5.21-1.07 7.069-2.93a10.034 10.034 0 0 0 2.931-7.07c0-2.63-1.061-5.21-2.931-7.07a10.056 10.056 0 0 0-7.069-2.93c-2.63 0-5.21 1.07-7.07 2.93a10.071 10.071 0 0 0-2.93 7.07zm-29.997-10h-133.5c-5.522 0-10 4.477-10 10s4.478 10 10 10h133.5c5.522 0 10-4.477 10-10s-4.477-10-10-10zm-173.167 64h-40c-5.522 0-10 4.477-10 10v40c0 5.523 4.478 10 10 10h40c5.522 0 10-4.477 10-10v-40c0-5.523-4.477-10-10-10zm-10 40h-20v-20h20v20zm10 64h-40c-5.522 0-10 4.477-10 10v40c0 5.523 4.478 10 10 10h40c5.522 0 10-4.477 10-10v-40c0-5.523-4.477-10-10-10zm-10 40h-20v-20h20v20zm135.542 0h-85.875c-5.522 0-10 4.477-10 10s4.478 10 10 10h85.875c5.522 0 10-4.477 10-10s-4.477-10-10-10z" />
        <path d="M486.004 79.59c-8.791-5.076-19.033-6.423-28.836-3.796-9.805 2.627-17.999 8.915-23.073 17.706l-9.625 16.67v.002l-13.465 23.323V34c0-18.748-15.252-34-34-34h-336c-18.748 0-34 15.252-34 34v444c0 18.748 15.252 34 34 34h336c18.748 0 34-15.252 34-34V287c0-.454-.041-.897-.1-1.335l79.383-137.495 9.625-16.671c10.476-18.146 4.237-41.432-13.909-51.909zm-23.659 15.522a17.876 17.876 0 0 1 13.659 1.798c8.596 4.963 11.551 15.993 6.588 24.589l-4.625 8.011-31.177-18 4.625-8.011a17.882 17.882 0 0 1 10.93-8.387zM340.627 367.39l-31.177-18 127.34-220.56 31.177 18-127.34 220.56zm-36.91 1.783 22.644 13.074-27.213 20.988 4.569-34.062zM391.004 478c0 7.72-6.28 14-14 14h-336c-7.72 0-14-6.28-14-14V34c0-7.72 6.28-14 14-14h336c7.72 0 14 6.28 14 14v134c0 .042.006.082.006.123L307.944 312H160.129c-5.522 0-10 4.477-10 10s4.478 10 10 10h136.268l-9.267 16.05a10.018 10.018 0 0 0-1.251 3.67l-9.786 72.95a9.999 9.999 0 0 0 16.018 9.248l58.283-44.95a10.025 10.025 0 0 0 2.553-2.918l38.057-65.916V478z" />
    </svg>
)

export default TestIcon;
