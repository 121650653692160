import React from "react";
import Hero from "../components/Hero";
import Testimonial from "../components/Testimonial";
import Quote from "../components/Quote";
import WhyUs from "../components/WhyUs";
import Placements from "../components/Placements";
import Programs from "../components/Programs";
import Layout from "../components/Layout";
import YoutubeDemo from "../components/YoutubeDemo";

import "../scss/main.scss";

const IndexPage = () => {
    return (
        <Layout>
            <Hero />
            <WhyUs />
            <YoutubeDemo/>
            <Testimonial />
            <Placements />
            <Programs />
            <Quote />
        </Layout>
    );
};

export default IndexPage;
