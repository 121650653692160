import * as React from "react"

const TeacherIcon = (props) => (
    <svg
        viewBox="0 0 136 145"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M91.88 116.86c15.378 2.314 25.86 6.919 25.86 12.219 0 7.628-21.702 13.811-48.47 13.811-26.767 0-48.469-6.183-48.469-13.811 0-5.3 10.482-9.903 25.86-12.219"
            fill="#481A42"
        />
        <mask id="a" fill="#fff">
            <path d="M46.659 140.95c-15.378-2.314-25.86-6.918-25.86-12.217 0-7.627 21.701-13.809 48.47-13.809 26.767 0 48.469 6.184 48.469 13.809 0 5.301-10.482 9.903-25.86 12.217" />
        </mask>
        <path
            d="M46.659 140.95c-15.378-2.314-25.86-6.918-25.86-12.217 0-7.627 21.701-13.809 48.47-13.809 26.767 0 48.469 6.184 48.469 13.809 0 5.301-10.482 9.903-25.86 12.217"
            fill="#481A42"
        />
        <path
            d="M47.105 137.983c-7.542-1.135-13.698-2.808-17.883-4.745-2.1-.972-3.55-1.94-4.433-2.816-.875-.868-.99-1.428-.99-1.689h-6c0 2.389 1.194 4.391 2.765 5.949 1.563 1.55 3.695 2.87 6.138 4.001 4.9 2.269 11.675 4.054 19.51 5.234l.893-5.934Zm-23.306-9.25c0-.38.295-1.412 2.485-2.887 2.083-1.403 5.295-2.785 9.534-3.992 8.435-2.403 20.263-3.93 33.45-3.93v-6c-13.58 0-25.987 1.565-35.094 4.159-4.533 1.292-8.42 2.886-11.242 4.787-2.716 1.829-5.133 4.43-5.133 7.863h6Zm45.47-10.809c13.186 0 25.014 1.527 33.45 3.931 4.238 1.207 7.45 2.589 9.534 3.992 2.19 1.475 2.485 2.507 2.485 2.886h6c0-3.433-2.418-6.034-5.133-7.863-2.823-1.9-6.709-3.494-11.242-4.786-9.107-2.594-21.514-4.16-35.095-4.16v6Zm45.469 10.809c0 .262-.116.822-.991 1.69-.883.876-2.333 1.844-4.432 2.816-4.185 1.937-10.341 3.609-17.884 4.744l.893 5.934c7.836-1.179 14.61-2.965 19.511-5.233 2.443-1.131 4.575-2.451 6.137-4.001 1.572-1.559 2.766-3.561 2.766-5.95h-6Z"
            fill="#481A42"
            mask="url(#a)"
        />
        <path
            d="m94.26 47.42-3.374 25.586c-.404 3.025-2.6 5.59-5.698 6.66l-1.867.66-6.716 38.32c-.299 1.686-1.879 2.926-3.734 2.93h-9.724c-1.855-.004-3.434-1.244-3.733-2.93L52.689 80.33l-1.867-.66c-3.1-1.07-5.295-3.635-5.699-6.66l-3.39-25.585c-.476-3.094.753-6.205 3.278-8.293a11.2 11.2 0 0 1 4.667-2.125l7.099-.337c1.624-.078 3.201.517 4.298 1.626l3.979 3.998c.376.378.833.68 1.343.886a4.31 4.31 0 0 0 1.616.313c.556 0 1.107-.107 1.616-.313.51-.206.967-.508 1.343-.886l3.967-3.996c1.097-1.108 2.674-1.704 4.298-1.626l7.099.337a11.199 11.199 0 0 1 4.667 2.125 9.366 9.366 0 0 1 2.753 3.769 8.672 8.672 0 0 1 .504 4.518Z"
            fill="#481A42"
            stroke="#481A42"
            strokeWidth={2}
            strokeMiterlimit={10}
        />
        <path
            d="M68.001 30.615c8.25 0 14.937-6.157 14.937-13.753S76.251 3.109 68.001 3.109 53.064 9.266 53.064 16.862s6.688 13.753 14.937 13.753Z"
            fill="#481A42"
            stroke="#481A42"
            strokeWidth={4}
        />
        <path
            d="m35.635 32.241-3.978 4c-.759.764-1.83 1.198-2.952 1.198-1.123 0-2.194-.434-2.952-1.197l-3.979-4c-1.097-1.11-2.674-1.704-4.298-1.627l-7.099.337a11.2 11.2 0 0 0-4.667 2.125c-2.525 2.089-3.755 5.2-3.278 8.294l3.39 25.585c.404 3.025 2.6 5.59 5.699 6.66l1.866.66 6.716 38.322"
            stroke="#481A42"
            strokeWidth={4}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
        <path
            d="M27.232 22.727c6.172 0 11.175-4.606 11.175-10.289s-5.003-10.29-11.175-10.29c-6.172 0-11.175 4.607-11.175 10.29 0 5.683 5.003 10.29 11.175 10.29Z"
            stroke="#481A42"
            strokeWidth={4}
        />
        <path
            d="m115.901 112.61 6.716-38.321 1.867-.66c3.099-1.07 5.295-3.635 5.699-6.66l3.39-25.586c.476-3.094-.754-6.205-3.278-8.293a11.207 11.207 0 0 0-4.668-2.125l-7.098-.337c-1.624-.078-3.202.518-4.298 1.626l-3.979 4c-.758.764-1.83 1.198-2.952 1.198-1.122 0-2.194-.434-2.952-1.197l-3.979-4"
            stroke="#481A42"
            strokeWidth={4}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
        <path
            d="M108.768 22.727c6.172 0 11.175-4.606 11.175-10.289s-5.003-10.29-11.175-10.29c-6.172 0-11.175 4.607-11.175 10.29 0 5.683 5.003 10.29 11.175 10.29Z"
            stroke="#481A42"
            strokeWidth={4}
        />
        <path
            d="m69.306 52.1 2.905 5.421c.212.396.62.669 1.094.733l6.497.86c.546.072 1 .427 1.171.91.17.484.028 1.017-.366 1.371l-4.7 4.22a1.322 1.322 0 0 0-.368.544c-.073.206-.09.426-.05.64l1.111 5.958c.091.498-.133 1.003-.578 1.302a1.557 1.557 0 0 1-1.522.107l-5.81-2.812a1.548 1.548 0 0 0-1.352 0l-5.81 2.812a1.556 1.556 0 0 1-1.521-.107c-.444-.299-.668-.802-.58-1.302l1.11-5.958a1.27 1.27 0 0 0-.419-1.184l-4.7-4.22a1.269 1.269 0 0 1-.366-1.37c.17-.484.626-.839 1.172-.912l6.496-.859a1.454 1.454 0 0 0 1.095-.733l2.905-5.42c.25-.447.75-.727 1.293-.727.543 0 1.043.28 1.293.726Z"
            fill="#FFA41C"
            stroke="#fff"
            strokeWidth={4}
            strokeMiterlimit={10}
        />
    </svg>
)

export default TeacherIcon;
