import React from 'react';
import Modal from './Modal';
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";


export default function Promotion({open, onClose}) {

    const data = useStaticQuery(graphql` {
        allPromotionJson {
            edges {
                node {
                    link
                    title
                    disable
                    banner {
                    childImageSharp {
                        gatsbyImageData
                     }
                    }
                }
            }
        }
    }
`);

    const promotion = data.allPromotionJson.edges[0].node

    return ( 
        !promotion.disable && 
        <Modal open={open} onClose={onClose} maxWidth="sm">
            <GatsbyImage
                alt={promotion.title}
                image={promotion.banner.childImageSharp.gatsbyImageData}
                loading="lazy"
            />
        </Modal>
    )
}
