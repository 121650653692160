import React from 'react';
import { motion } from "framer-motion";
import icons from '../images/placements';

const marqueeVariants = {
    animate: {
        x: [0, -1250],
        transition: {
            x: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 20,
                ease: "linear",
            },
        },
    },
};

export default function Placements() {
    return (
        <section className="container">
            <div className="placements">
                <h2 className="title">Shoot for the stars ⭐</h2>
                <h3 className="subtitle">Our students have aced the interviews at some of the best firms out there.</h3>
                <div className="placements__container">
                    <motion.div 
                        className="placements__marquee"
                        variants={marqueeVariants}
                        animate="animate"
                    >
                        {React.Children.toArray(Object.keys(icons).map(key=>(
                            <div className="placements__icon" data-id={key}>
                                <img src={icons[key]} alt={key} key={key} />
                            </div>
                        )))}
                        {React.Children.toArray(Object.keys(icons).map(key=>(
                            <div className="placements__icon"  data-id={key}>
                                <img src={icons[key]} alt={key} key={key} />
                            </div>
                        )))}
                    </motion.div>
                </div>
            </div>
        </section>
    )
}
