import deloitte from './deloitte.svg';
import deutsche from './deutsche-bank.svg';
import ernstYoung from './ernst-young.svg';
import jpMorgan from './jp-morgan.svg';
import kpmg from './kpmg.svg';
import pwc from './pwc.svg';
import moody from './moody-analytics.svg';
import thomsonReuters from './thomson-reuters.svg';
import genpact from './genpact.svg';
import hdfc from './hdfc-bank.svg';


export default {deloitte, deutsche, jpMorgan, kpmg, pwc, moody, thomsonReuters, genpact, hdfc, ernstYoung};