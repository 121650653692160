import React from 'react';
import TestIcon from '../Icons/TestIcon';
import ResourceIcon from '../Icons/ResourceIcon';
import LearningIcon from '../Icons/LearningIcon';
import TeacherIcon from '../Icons/TeacherIcon';

export default function WhyUs() {
    return (
        <section className="why-us">
            <div className="why-us__bg-layer">
                <div className="why-us__bg-layer__portion why-us__bg-layer__portion--left"></div>
                <div className="why-us__bg-layer__portion why-us__bg-layer__portion--right"></div>
            </div>
            <div className="container why-us__container">
                <h2 className="title why-us__title">We bring the whole Schbang!</h2>
                <h3 className="subtitle">What makes us special, sets us apart</h3>
                <div className="why-us__flex">
                    <div className="why-us__flex__item">
                        <div className="why-us__flex__item__svg">
                            <LearningIcon />
                        </div>
                        <h4 className="why-us__flex__item__title"><strong>Proactive Learning</strong></h4>
                    </div>
                    <div className="why-us__flex__item">
                        <div className="why-us__flex__item__svg">
                            <TeacherIcon />
                        </div>
                        <h4 className="why-us__flex__item__title"><strong>Passionate Teachers</strong></h4>
                    </div>
                    <div className="why-us__flex__item">
                        <div className="why-us__flex__item__svg">
                            <ResourceIcon />
                        </div>
                        <h4 className="why-us__flex__item__title"><strong>Carefully Designed Resources</strong></h4>
                    </div>
                    <div className="why-us__flex__item">
                        <div className="why-us__flex__item__svg">
                            <TestIcon />
                        </div>
                        <h4 className="why-us__flex__item__title"><strong>Regular Evaluation</strong></h4>
                    </div>
                </div>
            </div>
        </section>
    )
}
