import * as React from "react"

const ResourceIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512.003 512.003"
        fill="currentColor"
        className="BIIPvCRqoO6eeiLt4mCWl"
        {...props}
    >
        <path d="M256.001 0c-51.613 0-93.932 40.356-96.34 91.877a7.5 7.5 0 0 0 7.141 7.843 7.476 7.476 0 0 0 7.843-7.142c2.033-43.5 37.77-77.576 81.356-77.576 44.912 0 81.45 36.538 81.45 81.451 0 35.531-22.712 66.699-56.515 77.558a7.493 7.493 0 0 0-4.123 3.258L256 211.669l-20.813-34.401a7.497 7.497 0 0 0-4.123-3.258 81.36 81.36 0 0 1-53.159-54.365 7.5 7.5 0 0 0-14.381 4.263 96.35 96.35 0 0 0 60.248 63.47l25.811 42.662a7.498 7.498 0 0 0 12.834 0l25.81-42.662c38.532-13.625 64.224-49.81 64.224-90.928 0-53.183-43.268-96.45-96.45-96.45z" />
        <path d="M297.827 52.261c-15.494-15.493-40.706-15.495-56.2 0-13.68 13.68-15.272 34.932-4.794 50.386l-32.098 32.098a7.499 7.499 0 0 0 5.302 12.804 7.478 7.478 0 0 0 5.304-2.197l32.098-32.098a39.69 39.69 0 0 0 22.287 6.826c10.177 0 20.354-3.874 28.101-11.621 15.493-15.493 15.493-40.705 0-56.198zm-10.609 45.592c-9.645 9.645-25.341 9.646-34.985 0-9.645-9.645-9.645-25.339 0-34.985 4.822-4.823 11.157-7.234 17.492-7.234 6.336 0 12.671 2.412 17.493 7.234 9.646 9.645 9.646 25.34 0 34.985zm-69.267 181.485-67.023-20.845a7.499 7.499 0 0 0-9.39 4.934 7.503 7.503 0 0 0 4.936 9.389l67.023 20.845a7.52 7.52 0 0 0 2.229.34 7.5 7.5 0 0 0 2.225-14.663zm0 26.574-67.023-20.845a7.5 7.5 0 1 0-4.454 14.323l67.023 20.845a7.52 7.52 0 0 0 2.229.34 7.5 7.5 0 0 0 2.225-14.663zm0 26.574-67.023-20.845a7.501 7.501 0 0 0-9.39 4.934 7.503 7.503 0 0 0 4.936 9.389l67.023 20.845a7.52 7.52 0 0 0 2.229.34 7.5 7.5 0 0 0 2.225-14.663zm221.972-118.872c1.935 1.412-1.932-1.411 0 0z" />
        <path d="M439.923 213.614a7.5 7.5 0 0 0-6.707-1.086l-10.697 3.421v-12.373a7.501 7.501 0 0 0-9.785-7.144l-156.733 50.135-95.275-30.476a7.5 7.5 0 0 0-4.571 14.287l92.346 29.539v152.695l-144.019-46.068V213.85l22.125 7.078a7.5 7.5 0 0 0 4.571-14.287l-31.91-10.208a7.498 7.498 0 0 0-9.785 7.144v12.373l-10.697-3.421a7.5 7.5 0 0 0-9.785 7.144v227.411a7.501 7.501 0 0 0 5.215 7.144l179.501 57.42a7.56 7.56 0 0 0 4.57 0l92.231-29.5a7.5 7.5 0 1 0-4.571-14.287l-82.445 26.371V472.71l164.501-52.622v21.521l-52.516 16.802a7.5 7.5 0 0 0-4.858 9.428 7.504 7.504 0 0 0 7.142 5.217c.757 0 1.527-.116 2.287-.358l57.73-18.47a7.502 7.502 0 0 0 5.215-7.144V219.673a7.504 7.504 0 0 0-3.08-6.059zM248.501 494.231 84 441.609v-21.521l164.501 52.622v21.521zm0-37.27L84 404.338V229.946l5.482 1.754v140.32a7.501 7.501 0 0 0 5.215 7.144l153.804 49.198v28.599zm15-142.598v-54.444L407.52 213.85v152.695l-144.019 46.068v-98.25zm164.501-57.41v147.386l-164.501 52.622v-28.598l153.804-49.198a7.502 7.502 0 0 0 5.215-7.144V231.699l5.482-1.754v27.008z" />
        <path d="M355.341 252.955a7.497 7.497 0 0 0-6.707-1.086l-39.859 12.75a7.502 7.502 0 0 0-5.215 7.144v42.222a7.501 7.501 0 0 0 9.785 7.144l39.859-12.75a7.502 7.502 0 0 0 5.215-7.144v-42.222a7.496 7.496 0 0 0-3.078-6.058zM343.42 295.76l-24.859 7.952v-26.474l24.859-7.952v26.474z" />
    </svg>
)

export default ResourceIcon;
