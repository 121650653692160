import React from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { graphql, Link, useStaticQuery } from "gatsby";

export default function Programs() {

    const data = useStaticQuery(graphql` {
        allProgramsJson {
            edges {
                node {
                  id
                  description
                  title
                  link
                }
            }
        }
    }
`);

    return (
        <section className="programs">
            <div className="programs__bg-layer">
                <div className="programs__bg-layer__portion programs__bg-layer__portion--left"></div>
                <div className="programs__bg-layer__portion programs__bg-layer__portion--right"></div>
            </div>
            <div className="container programs__container">
                <h2 className="title programs__title">Our Programs</h2>
                <h3 className="subtitle programs__subtitle">Chart your own success story.</h3>

                <div className="programs__flex">
                    {
                        data.allProgramsJson.edges.map(({ node })=> (
                            <div className="programs__flex__course" key={node.id}>
                                <h3 className="title programs__flex__course__title">{node.title}</h3>
                                <div className="programs__flex__course__description">
                                    <p className="programs__flex__course__description__text">{node.description}</p>
                                </div>
                                <Link to={node.link} className="button button--medium button--basic programs__flex__course__btn">
                                    {"Learn more "} <FaAngleRight/>
                                </Link>
                            </div>
                        ))
                    }
                </div>

            </div>
           
        </section>
    )
}
