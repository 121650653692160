import * as React from "react"

const Slash = (props) => (
    <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        className="hidden md:block mx-1 h-5 w-5 text-indigo-600"
        {...props}
    >
        <path d="M11 0h3L9 20H6l5-20z" />
    </svg>
)

export default Slash;
