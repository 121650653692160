import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaQuoteLeft } from "react-icons/fa";
import useInterval from "../hooks/useInterval";
import { graphql, useStaticQuery } from "gatsby";
import Slash from "../Icons/Slash";
import { IoIosVideocam } from "react-icons/io";
import classNames from "classnames";
import { motion } from "framer-motion";
import Modal from "./Modal";

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

export default function Testimonial() {
    const [currIdx, setCurrIdx] = useState(0);

    const data = useStaticQuery(graphql`
    {
      allTestimonialsJson {
        edges {
          node {
            content
            id
            name
            type
            videoId
            avatar {
              childImageSharp {
                gatsbyImageData(width: 400, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  `);
    const totalCount = data.allTestimonialsJson.edges.length || 0;

    useInterval(() => handleClick(1), 8000);

    const handleClick = (offset) => {
        setCurrIdx((currIdx + offset + totalCount) % totalCount);
    };

    const moveTo = (targetIdx) => {
        setCurrIdx(targetIdx);
    };

    const [ytVideoId, setYtVideoId] = useState("");

    const handleClose = () => {
        setYtVideoId("");
    };

    return (
        <section className="container testimonial">
            {ytVideoId && (
                <Modal open={!!ytVideoId} type="video" onClose={handleClose}>
                    <iframe
                        className="video-iframe"
                        src={`https://www.youtube.com/embed/${ytVideoId}?autoplay=1`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </Modal>
            )}
            <h2 className="title">A lot of happy students</h2>
            <h3 className="subtitle">Here’s what they have to say...</h3>
            <div className="testimonial__flex">
                {React.Children.toArray(
                    data.allTestimonialsJson.edges.map(({ node }, idx) => (
                        <motion.div
                            key={node.id}
                            className={`testimonial__box ${
                                currIdx === idx ? "testimonial__box--animation" : ""
                            }`}
                            data-id={idx + 1}
                            style={{ display: currIdx !== idx ? "none" : "block" }}
                            drag="x"
                            dragConstraints={{ left: 0, right: 0 }}
                            dragElastic={1}
                            onDragEnd={(e, { offset, velocity }) => {
                                const swipe = swipePower(offset.x, velocity.x);

                                if (swipe < -swipeConfidenceThreshold) {
                                    handleClick(1);
                                } else if (swipe > swipeConfidenceThreshold) {
                                    handleClick(-1);
                                }
                            }}
                        >
                            <div className="testimonial__box__text">
                                <FaQuoteLeft className="testimonial__box__quote testimonial__box__quote--left" />
                                <h3>{node.content}</h3>
                            </div>
                            <div className="testimonial__box__user">
                                <GatsbyImage
                                    alt={node.name}
                                    image={node.avatar.childImageSharp.gatsbyImageData}
                                    loading="lazy"
                                    className="testimonial__box__user__wrapper"
                                />
                                <p className="testimonial__box__user__name">{node.name}</p>
                                <Slash className="testimonial__box__user__slash" />
                                <p className="testimonial__box__user__location">{node.type}</p>
                            </div>
                            {node.videoId && (
                                <button
                                    className="button button--small button--basic"
                                    style={{ margin: "auto" }}
                                    onClick={() => setYtVideoId(node.videoId)}
                                >
                  Watch Video &nbsp; <IoIosVideocam />
                                </button>
                            )}
                        </motion.div>
                    ))
                )}
            </div>
            <div className="testimonial__controls">
                {React.Children.toArray(
                    Array(totalCount)
                        .fill(0)
                        .map((item, idx) => (
                            // eslint-disable-next-line react/jsx-key
                            <div
                                onClick={() => moveTo(idx)}
                                className={classNames(
                                    "testimonial__controls__circle",
                                    idx === currIdx && "testimonial__controls__circle--active"
                                )}
                            ></div>
                        ))
                )}
            </div>
        </section>
    );
}
