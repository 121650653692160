import React from 'react'


export default function Quote() {
    return (
        <div className="quote">
            <div className="container quote__container">
                <h3>"Every child can learn. Just not on the same day or in the same way" - George Evans</h3>
            </div>
        </div>
    )
}
